import { UserProvider } from "@datwyler/mfe-shared-components";
import Router from "./Router";

const App = (props: any) => {
  const { locale, version, module } = props;

  return (
    <UserProvider>
      <Router locale={locale} version={version} module={module} />
    </UserProvider>
  );
};

export default App;
