export const headerPadding = "28px 32px";
export const headerPaddingMobile = "12px 16px 12px 16px";

export const headerStyle: React.CSSProperties = {
  textAlign: "center",
  lineHeight: "40px",
};

export const siteNameStyle: React.CSSProperties = {
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  fontFamily: "Poppins",
  verticalAlign: "top",
};

export const fontStyle: React.CSSProperties = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  fontFamily: "Poppins",
  verticalAlign: "top",
};

export const dropdownMenuItemStyle: React.CSSProperties = {
  padding: "16px",
  gap: "16px",
};

export const sideMenuLabelStyle: React.CSSProperties = {
  fontStyle: "normal",
  fontSize: "14px",
};

export const sideMenuLabelSelectedStyle: React.CSSProperties = {
  fontStyle: "normal",
  fontSize: "14px",
};
