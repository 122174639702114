import { CSSProperties, useContext } from "react";
import { useIntl } from "react-intl";
import {
  dropdownMenuItemStyle,
  fontStyle,
  headerStyle,
  headerPadding,
  headerPaddingMobile,
} from "../../utils/styles";
import {
  type MenuProps,
  Col,
  Row,
  Space,
  Dropdown,
  Typography,
  Layout,
  Button,
} from "antd";
import { getFlagIcon } from "../../utils";
import {
  NotificationIcon,
  MenuBurgerIcon,
  DatwylerLogoDark,
  DatwylerLogo,
  DarkModeContext,
  useLocale,
  LOCALES,
  HEADERHEIGHT,
  HEADERHEIGHTMOBILE,
  BasicDropdown,
  DatwylerAvatar,
} from "@datwyler/mfe-shared-components";

import MasqSubTenantSelect from "./MasqSubTenantSelect";

const { Header } = Layout;
const { Text } = Typography;

const commonIconStyle: CSSProperties = {
  top: "6px",
  position: "relative",
};

const HeaderBar = (props: any) => {
  const {
    locale,
    user = {},
    collapsed,
    setCollapsed,
    isMobileScreen,
    tenantInfo,
    tenantInfoLoading,
  } = props;
  const { setLocale } = useLocale();
  const intl = useIntl();
  const { isDarkMode }: any = useContext(DarkModeContext);

  const handleChangeLanguage: MenuProps["onClick"] = (e) => {
    setLocale(e.key);
  };

  const languageMenuItems: MenuProps["items"] = Object.keys(LOCALES).map(
    (lang) => {
      const loc = LOCALES[lang];
      return {
        key: loc,
        label: intl.formatMessage({ id: loc }),
        icon: getFlagIcon(loc, {}),
        style: { ...fontStyle, ...dropdownMenuItemStyle, gap: "12px" },
      };
    }
  );

  return (
    <Header
      style={{
        ...headerStyle,
        height: isMobileScreen ? HEADERHEIGHTMOBILE : HEADERHEIGHT,
        padding: isMobileScreen ? headerPaddingMobile : headerPadding,
        borderBottom: isDarkMode ? "1px solid #5A5C5F" : "1px solid #E1E1E4",
        background: isDarkMode ? "#191A24" : "#FFFFFF",
      }}
    >
      <Row style={{ height: "40px" }}>
        <Col
          xs={{ span: 6 }}
          xl={{ span: 0 }}
          style={{ cursor: "pointer", textAlign: "left", paddingLeft: "8px" }}
        >
          <Button
            onClick={() => setCollapsed(!collapsed)}
            type="text"
            style={{ width: "40px", height: "40px" }}
            icon={
              <MenuBurgerIcon
                width={"24px"}
                height={"24px"}
                fill={isDarkMode ? "#FFFFFF" : "#12141C"}
                style={{ verticalAlign: "middle" }}
              />
            }
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          xl={{ span: 3 }}
          style={{
            textAlign: isMobileScreen ? "center" : "left",
            height: isMobileScreen ? "24" : "40",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.assign("/");
          }}
        >
          {isDarkMode ? (
            <DatwylerLogoDark
              style={{ verticalAlign: "middle" }}
              height={isMobileScreen ? "24" : "40"}
              width={isMobileScreen ? "132" : "216"}
            />
          ) : (
            <DatwylerLogo
              style={{ verticalAlign: "middle" }}
              height={isMobileScreen ? "24" : "40"}
              width={isMobileScreen ? "132" : "216"}
            />
          )}
        </Col>
        <Col
          xs={{ span: 0 }}
          xl={{ span: 21 }}
          style={{ textAlign: "right", height: "40px" }}
        >
          {!isMobileScreen && (
            <MasqSubTenantSelect
              tenantInfo={tenantInfo}
              user={user}
              tenantInfoLoading={tenantInfoLoading}
            />
          )}
          <BasicDropdown
            onClick={handleChangeLanguage}
            items={languageMenuItems}
            labelStyle={{ ...fontStyle, marginLeft: 24 }}
            label={
              <>
                {getFlagIcon(locale, commonIconStyle)}
                {intl.formatMessage({ id: locale })}
              </>
            }
          />
          <Space style={{ marginLeft: 24, verticalAlign: "top" }}>
            <NotificationIcon
              width={"24px"}
              height={"24px"}
              style={commonIconStyle}
            />
          </Space>
          <Space
            style={{
              marginLeft: 24,
              verticalAlign: "top",
              position: "relative",
              top: "2px",
            }}
          >
            <img src={DatwylerAvatar} width={36} height={36} alt="avatar" />
            <Text style={{ ...fontStyle }}>
              {user.firstName + " " + user.lastName}
            </Text>
          </Space>
        </Col>
        <Col
          xs={{ span: 6 }}
          xl={{ span: 0 }}
          style={{ textAlign: "right", height: "24px" }}
        >
          <Dropdown
            menu={{
              onClick: handleChangeLanguage,
              items: languageMenuItems,
              style: { padding: 0 },
            }}
          >
            <Space style={{ ...fontStyle, marginLeft: 24, cursor: "pointer" }}>
              {getFlagIcon(locale, { verticalAlign: "middle" })}
            </Space>
          </Dropdown>
          <Space
            style={{
              marginLeft: isMobileScreen ? 8 : 24,
              verticalAlign: "top",
            }}
          >
            <NotificationIcon
              width={"24px"}
              height={"24px"}
              style={{ verticalAlign: "middle" }}
            />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
