import { useCallback, useEffect, useState } from "react";
import { MenuProps } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  dropdownMenuItemStyle,
  fontStyle,
  siteNameStyle,
} from "../../utils/styles";
import {
  ACTIVE,
  useSubTenant,
  BasicDropdown,
  lodash as _,
} from "@datwyler/mfe-shared-components";

const dropdownStyle = {
  ...fontStyle,
  ...dropdownMenuItemStyle,
};

const MasqSubTenantSelect = (props) => {
  const { user, tenantInfo, tenantInfoLoading, handleOnSelect } = props;
  const { fetchSubTenant, fetchSubTenantData, isfetchSubTenantLoading } =
    useSubTenant();
  const location = useLocation();
  const navigate = useNavigate();
  const [subTenantOptions, setSubTenantOptions] = useState<MenuProps["items"]>(
    []
  );

  const fetchSubTenants = useCallback(
    (tenantId) => {
      if (tenantId) {
        fetchSubTenant({
          variables: {
            tenantId: tenantId,
            page: { number: 0, size: 999999 },
            filter: [`status:${ACTIVE}`],
            sort: [`name,asc`],
          },
        });
      }
    },
    [fetchSubTenant]
  );

  useEffect(() => {
    const userTenantId = _.get(user, "tenant.id", null);
    const userTenantLevel = _.get(user, "tenant.level", -1);
    if (userTenantLevel === 0) {
      fetchSubTenants(userTenantId);
    }
  }, [user, fetchSubTenants]);

  useEffect(() => {
    const subTenantsData = _.get(fetchSubTenantData, "subTenants.tenants", []);
    const userTenantId = _.get(user, "tenant.id", null);
    const userTenantName = _.get(user, "tenant.name", null);
    const options = [];
    if (userTenantId && userTenantName) {
      options.push({
        key: userTenantId,
        label: userTenantName,
        style: dropdownStyle,
      });
    }
    subTenantsData.map((tenant) => {
      const canManage = _.get(tenant, "managed", false);
      if (canManage) {
        options.push({
          key: tenant.id,
          label: tenant.name,
          style: dropdownStyle,
        });
      }
    });
    setSubTenantOptions(options);
  }, [user, fetchSubTenantData]);

  const handleChangeMasqTenant = (e) => {
    const userTenantId = _.get(user, "tenant.id", null);
    const state = {
      ...location.state,
      tenantId: e.key,
      isMasqAsSubTenant: e.key !== userTenantId,
    };
    if (handleOnSelect) handleOnSelect();
    navigate(location.pathname, { state: state });
  };

  return (
    <BasicDropdown
      onClick={handleChangeMasqTenant}
      items={subTenantOptions}
      labelStyle={siteNameStyle}
      label={_.get(tenantInfo, "name", "")}
      isSpinning={isfetchSubTenantLoading || tenantInfoLoading || false}
    />
  );
};

export default MasqSubTenantSelect;
