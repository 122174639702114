import { useCallback, useContext, useEffect, useState } from "react";
import { Layout, Alert } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderBar from "../component/HeaderBar";
import SideBar from "../component/SideBar";
import {
  useScreenOptions,
  DEFAULTNAVBARWIDTH,
  LICENSE_STATUS,
  UserContext,
  TenantIdContext,
  lodash as _,
  commonStyles,
  useIntl,
  moment,
} from "@datwyler/mfe-shared-components";

const layoutStyle = {
  overflow: "hidden",
};

const getDefaultIsCollapsed = () => {
  return window.localStorage.getItem("isNavBarCollapsed") === "true";
};

const { Body16MediumPrimary } = commonStyles;

const NavBar = (props: any) => {
  const intl = useIntl();
  const { locale, version, module } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { user }: any = useContext(UserContext);
  const { tenantInfo, tenantInfoLoading }: any = useContext(TenantIdContext);
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    getDefaultIsCollapsed()
  );

  const { isMobileScreen } = useScreenOptions();

  const triggerNavBarWidth = useCallback(
    (collapsed) => {
      let newNavBarWidth;

      if (isMobileScreen) {
        newNavBarWidth = "0";
      } else if (!collapsed) {
        newNavBarWidth = DEFAULTNAVBARWIDTH.toString();
      } else {
        newNavBarWidth = "160";
      }

      window.localStorage.setItem("navBarWidth", newNavBarWidth);
      window.dispatchEvent(new Event("navBarChanged"));
    },
    [isMobileScreen]
  );

  useEffect(() => {
    let collapsed = false;
    if (isMobileScreen) {
      collapsed = true;
    } else {
      collapsed = getDefaultIsCollapsed();
    }

    setIsSideBarCollapsed(collapsed);
    triggerNavBarWidth(collapsed);
  }, [isMobileScreen, triggerNavBarWidth]);

  const getLocationState = useCallback(() => {
    const state = { ...location.state, tenantId: null };
    if (location?.state?.tenantId) {
      // if state already has a tenantId eg. sub tenant id
      state.tenantId = location.state.tenantId;
    } else if (user?.tenant?.id) {
      // if on first load no tenantId in state
      state.tenantId = user.tenant.id;
    }

    return state;
  }, [location, user]);

  useEffect(() => {
    if (user?.tenant?.id && location?.pathname && !location?.state?.tenantId) {
      navigate(location.pathname, { state: getLocationState() });
    }
  }, [user, getLocationState, location, navigate]);

  const getLicenseText = () => {
    const licenseInfo = _.get(tenantInfo, "license", null);
    const status = _.get(licenseInfo, "status", null);
    if (
      status === LICENSE_STATUS.EXPIRING ||
      status === LICENSE_STATUS.EXPIRED
    ) {
      const endDate = _.get(licenseInfo, "endDate", null);
      let text = <></>;
      switch (status) {
        case LICENSE_STATUS.EXPIRING:
          text = (
            <Body16MediumPrimary>
              {intl.formatMessage({ id: "license_expiring_banner" })}
              &nbsp;{moment.unix(endDate).format("DD-MM-YYYY")}
            </Body16MediumPrimary>
          );
          break;
        case LICENSE_STATUS.EXPIRED:
          text = (
            <Body16MediumPrimary>
              {intl.formatMessage({ id: "license_expired_banner" })}
            </Body16MediumPrimary>
          );
          break;
      }
      return (
        <Alert
          banner
          showIcon={false}
          message={text}
          type={status === LICENSE_STATUS.EXPIRED ? "error" : "warning"}
          style={{ textAlign: "center" }}
        />
      );
    }
    return <></>;
  };

  return (
    <Layout style={layoutStyle}>
      {getLicenseText()}
      <HeaderBar
        user={user}
        locale={locale}
        collapsed={isSideBarCollapsed}
        setCollapsed={setIsSideBarCollapsed}
        isMobileScreen={isMobileScreen}
        tenantInfo={tenantInfo}
        tenantInfoLoading={tenantInfoLoading}
      />
      <SideBar
        user={user}
        collapsed={isSideBarCollapsed}
        setCollapsed={setIsSideBarCollapsed}
        triggerNavBarWidth={triggerNavBarWidth}
        isMobileScreen={isMobileScreen}
        tenantInfo={tenantInfo}
        module={module}
      />
    </Layout>
  );
};

export default NavBar;
