import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./containers/NavBar";
import MockModuleSelect from "./containers/MockModuleSelect";
import {
  DarkModeContext,
  TenantIdProvider,
  lodash as _,
  PageNotFound,
} from "@datwyler/mfe-shared-components";
import { ConfigProvider } from "antd";
import { useContext } from "react";
import { menuPages } from "./utils";

const Router = (props: any) => {
  const { locale, version, module } = props;

  const { theme } = useContext<any>(DarkModeContext);
  const location = useLocation();
  const tenantId = _.get(location, "state.tenantId");

  return (
    <Routes>
      <Route path="/" element={<MockModuleSelect />} />
      {menuPages.map((path) => {
        return (
          <Route
            key={path}
            path={`${path}/*`}
            element={
              <TenantIdProvider tenantId={tenantId}>
                <ConfigProvider theme={theme}>
                  <NavBar locale={locale} version={version} module={module} />
                </ConfigProvider>
              </TenantIdProvider>
            }
          />
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
