import { Button, Col, Layout, Row, Space, Typography } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import {
  useScreenOptions,
  Circle,
  Overlay,
  DataCenterImg,
  Industry4Img,
  LoginBg,
  SmartBuildingImg,
} from "@datwyler/mfe-shared-components";

const { Text } = Typography;

const MockModuleSelect = () => {
  const { isMobileScreen } = useScreenOptions();
  const layoutStyle = {
    background: `url(${LoginBg})`,
    width: "100%",
    height: "100vh",
    backgroundSize: "100% 100%",
  };

  const contentStyle = {
    width: "100%",
    height: "calc(100vh - 52px)",
    zIndex: 1,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const footerStyle: any = {
    padding: 0,
    zIndex: 1,
    background: "none",
    textAlign: "center",
    height: "52px",
  };

  const chooseModuleContainerStyle: any = {
    width: "50%",
    minWidth: "320px",
    background: "#FFFFFF",
    color: "black",
    display: "block",
    boxShadow: "0px 0px 36px 0px #0000000D",
    border: "1px solid #E1E1E4",
    borderRadius: "6px",
    padding: "56px 24px 56px 24px",
  };

  const moduleStyle: any = {
    width: "100%",
    border: "1px dashed #AFAFAF",
    borderRadius: "16px",
    alignItems: "flex-start",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
  };

  const moduleImgStyle = {
    borderTopLeftRadius: moduleStyle.borderRadius,
    borderTopRightRadius: moduleStyle.borderRadius,
  };

  const moduleTextContainerStyle: any = {
    height: "52px",
    rowGap: 0,
  };

  const moduleTextStyle: any = {
    color: "#12141C",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  };

  const handleOnClick = (module) => {
    switch (module) {
      case "dataCenter":
        window.location.assign("/home");
        break;
      case "smartBuilding":
        window.location.assign("/smart-building");
        break;
      case "industry4":
        break;
      case "configurator":
        window.open("https://www.mdcconfigurator.com", "_blank");
        break;
      case "smartBuilding-configurator":
        window.open("https://smartbuildingconfigurator.com", "_blank");
        break;
      default:
        return;
    }
  };

  const buttonStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px",
  };

  return (
    <Layout style={layoutStyle}>
      <Overlay backgroundColor={"#0B0B0B99"} overrideStyle={{ zIndex: 1 }} />
      <Content style={contentStyle}>
        <Space style={chooseModuleContainerStyle}>
          <Row
            style={{
              color: "#12141C",
              textAlign: "center",
              marginBottom: "32px",
            }}
          >
            <Col span={24}>
              <Text
                style={{ color: "#12141C", fontWeight: 500, fontSize: "20px" }}
              >
                Choose Module
              </Text>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 12 }} xl={{ span: 8 }}>
              <Space style={moduleStyle}>
                <img
                  alt={"data_center_img"}
                  src={DataCenterImg}
                  style={moduleImgStyle}
                  width={"100%"}
                />
                <Space style={moduleTextContainerStyle}>
                  <Row>
                    <Col span={24}>
                      <Text style={moduleTextStyle}>Data Center</Text>
                    </Col>
                    <Col span={24}>
                      <Button
                        onClick={() => handleOnClick("configurator")}
                        type="text"
                        style={buttonStyle}
                      >
                        Plan
                      </Button>
                      <Text style={moduleTextStyle}>|</Text>
                      <Button type="text" style={buttonStyle} disabled>
                        Build
                      </Button>
                      <Text style={moduleTextStyle}>|</Text>
                      <Button
                        onClick={() => handleOnClick("dataCenter")}
                        type="text"
                        style={buttonStyle}
                      >
                        Run
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Space>
            </Col>
            <Col xs={{ span: 12 }} xl={{ span: 8 }}>
              <Space style={moduleStyle}>
                <img
                  alt={"smart_building_img"}
                  src={SmartBuildingImg}
                  style={moduleImgStyle}
                  width={"100%"}
                />
                <Space style={moduleTextContainerStyle}>
                  <Row>
                    <Col span={24}>
                      <Text style={moduleTextStyle}>Smart Building</Text>
                    </Col>
                    <Col span={24}>
                      <Button
                        onClick={() =>
                          handleOnClick("smartBuilding-configurator")
                        }
                        type="text"
                        style={buttonStyle}
                      >
                        Plan
                      </Button>
                      <Text style={moduleTextStyle}>|</Text>
                      <Button type="text" style={buttonStyle} disabled>
                        Build
                      </Button>
                      <Text style={moduleTextStyle}>|</Text>
                      <Button
                        onClick={() => handleOnClick("smartBuilding")}
                        type="text"
                        style={buttonStyle}
                      >
                        Run
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Space>
            </Col>
            <Col xs={{ span: 12 }} xl={{ span: 8 }}>
              <Space
                style={moduleStyle}
                className={"custom-module-select disabled"}
                onClick={() => handleOnClick("industry4")}
              >
                <img
                  alt={"industry_4_img"}
                  src={Industry4Img}
                  style={moduleImgStyle}
                  width={"100%"}
                />
                <Space direction="vertical" style={moduleTextContainerStyle}>
                  <Text style={moduleTextStyle}>Industry 4.0</Text>
                  <Text
                    style={{
                      color: "#7C7C7C",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    {"(coming soon)"}
                  </Text>
                </Space>
              </Space>
            </Col>
          </Row>
        </Space>
      </Content>
      <Footer style={footerStyle}>
        <Space
          style={{
            width: "360px",
            justifyContent: "center",
            gap: isMobileScreen ? "8px" : "32px",
            alignItems: "center",
          }}
        >
          <Button type="link" style={{ color: "#12141C", fontWeight: 400 }}>
            About
          </Button>
          <Circle width={"4px"} />
          <Button type="link" style={{ color: "#12141C", fontWeight: 400 }}>
            Privacy and Terms
          </Button>
          <Circle width={"4px"} />
          <Button type="link" style={{ color: "#12141C", fontWeight: 400 }}>
            FAQ
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
};

export default MockModuleSelect;
